import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import IconClose from './SvgIcons/IconClose.svg';
import IconCopyThin from './SvgIcons/IconCopyThin.svg';
import IconEdit from './SvgIcons/IconEdit.svg';
import IconEyeShow from './SvgIcons/IconEyeShow.svg';
import IconEyeHide from './SvgIcons/IconEyeHide.svg';
import ValidateUserInput from '../components/common/ValidateUserInput';

const PlaceholderColor = {
    dark: 'placeholder-gray-900',
    light: 'placeholder-gray-500',
    extraLight: 'placeholder-gray-300',
}

const Input = React.forwardRef(({ inputDataType, mandatory, characterLimit, minimumLength, maximumLength, validationHandler, handleKeyPress, handlePaste, className = '', containerClass = '', type, placeholder, maxLength, minLength, props, register, error, disabled, id, isRequiredField, isEditable, value, variant, placeholderColor, onChange
, isShowPassword, setIsShowPassword, onBlur, isApply, handlerApply, dataAutomation, dataAutomationForApply, showNoError = false,
isClearable, setValue = () => { }, watch, minRange, maxRange, isOnlyText, isShowCopyIcon, onCopy, isUpperCase, isSpecialCharacter, viewOnly, viewMore = false, iconIndicator, inputValue, defaultValue, onKeyPress, placeholderClass = '' }, ref) => {
    const [isEditableV2, setIsEditableV2] = useState(isEditable);

    const handlerChangeEditable = (e) => {
        e.preventDefault();

        setIsEditableV2(!isEditableV2)
    }
    const handleNumberRange = event => {
        let { value, min, max } = event.target;

        if (!isNaN(value)) {
            value = (min == '' && max == '')
                ? value
                : Math.max(Number(min), Math.min(Number(max), Number(value)));

            onChange
                ? onChange(value == 0 ? '' : value)
                : setValue(id, value == 0 ? '' : value)
        } else {
            setValue && setValue(id, value.replace(/[^0-9]/g, ''));
        }
    };

    const handlerNumber = event => {
        let { value } = event.target;
        const replaced_val = value.replace(/[^0-9]/g, '');

        if (typeof maxLength != 'undefined' && replaced_val.length <= maxLength) {
            onChange
                ? onChange(replaced_val)
                : setValue(id, replaced_val)
        } else {
            const trimmed_val = replaced_val.substring(0, maxLength);
            onChange
                ? onChange(trimmed_val)
                : setValue(id, trimmed_val)
        }
    }

    const handlerText = event => {
        event.preventDefault();
        let { value } = event.target;

        if (isNaN(value)) {
            onChange
                ? onChange(value.replace(/[0-9]/g, ''))
                : setValue(id, value.replace(/[0-9]/g, ''))
        } else {
            setValue(id, '')
            onChange
                ? onChange('')
                : setValue(id, '')
        }
    }

    const handleFreeText = event => {
        event.preventDefault();
        let { value } = event.target;

        onChange(value)
    }
    const toUpperCase = (ele) => {
        if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(ele.target.value)) {
            setValue(id, ele.target.value.toUpperCase())
        } else {
            setValue(id, ele.target.value.slice(0, -1))
        }
    }

    const toSpecialCharacter = (ele) => {
        if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(ele.target.value)) {
            setValue(id, ele.target.value)
        } else {
            setValue(id, ele.target.value.slice(0, -1))
        }
    }
    const onChangeHandler = useMemo(() => {
        if (minRange || maxRange) {
            return handleNumberRange;
        }
        if (type === 'number') {
            return handlerNumber;
        }
        if (type === 'text' && isOnlyText) {
            return handlerText;
        }
        if (type === 'text' && isSpecialCharacter) {
            return toSpecialCharacter;
        }
        if (type === 'text' && isUpperCase) {
            return toUpperCase;
        }
        else {
            return onChange ? handleFreeText : null;
        }
    }, [handlerNumber, type, setValue, minRange, maxRange, handleNumberRange, isOnlyText]);

    const handlerClearInput = (e) => {
        setValue(id, '');
    }

    return (
        <div className={`${viewMore ? '' : 'inputContainer'} ${containerClass || ''}`}>
            {
                variant === 'simple'
                    ? (
                        <input
                            {...(register && register(id, { required: isRequiredField }))}
                            {...(ref && { ref })}
                            id={id}
                            type='text'
                            className={`${className} w-full text-base font-semibold text-gray-900 bg-transparent-0  ${PlaceholderColor[placeholderColor]}`}
                            placeholder={placeholder}
                            value={value}
                            defaultValue={defaultValue}
                            maxLength={maxLength}
                            {...(onChange && { onChange: onChange })}
                            {...(onBlur && { onBlur: onBlur })}
                            {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-input') })}
                            {...props}
                        />
                    )
                    : (
                        <>
                            <input
                                id={id}
                                className={`${className} ${error && error.message && 'inputError'} ${viewOnly ? 'inputViewOnly' : disabled && 'inputDisabled'} ${type === 'password' ? 'pr-8' : ''} ${isShowCopyIcon ? 'pr-10' : ''} ${iconIndicator ? 'pl-10 iconIndicator placeholder:pl-10' : ''} inputDefault`}
                                placeholder={' '}
                                disabled={viewOnly || disabled || !isEditableV2}
                                value={value}
                                defaultValue={defaultValue}
                                onBlur={onBlur}
                                minLength={minimumLength ?? minLength}
                                maxLength={maximumLength ?? maxLength}
                                type={inputDataType ? inputDataType : (type === 'number' || isShowPassword || maxRange || minRange) ? 'text' : type}
                                {...(handleKeyPress && { "onKeyPress": event => handleKeyPress(event) })}
                                {...((register && !validationHandler) && { ...register(id, { required: isRequiredField }) })}
                                {...((register && validationHandler) && { 
                                    ...register(id, { required: isRequiredField }),
                                    onChange: (event) => {
                                        validationHandler?.onChange(event);
                                        if (onChange) onChange(event?.target?.value);
                                        register(id, { required: isRequiredField })?.onChange(event);
                                    },
                                    onBlur: (event) => {
                                        validationHandler?.onBlur(event);
                                        if (onBlur) onBlur(event?.target?.value);
                                        register(id, { required: isRequiredField })?.onChange(event);
                                    },
                                })}
                                {...(onKeyPress && { "onKeyPress": event => onKeyPress(event) })}
                                {...(ref && { ref })}
                                {...((!register && validationHandler) && validationHandler)}
                                {...((onChangeHandler && !validationHandler) && { 'onInput': onChangeHandler })}
                                {...((dataAutomation || id) && { 'data-automation': dataAutomation || (id + '-input') })}
                                {
                                ...((type === "number" || type === "date" || 'datetime-local') ?
                                    {
                                        min: minRange || minLength,
                                        max: maxRange || maxLength
                                    } :
                                    {
                                        minLength: minLength,
                                        maxLength: maxLength
                                    })
                                }
                                {...props}
                            />
                            {placeholder && <label className={`${iconIndicator ? '!pl-10 iconIndicator' : ''} ${placeholderClass}`} htmlFor={id}>{placeholder}</label>}
                        </>
                    )
            }

            {iconIndicator && <div className='absolute left-0 top-1/2 -translate-y-1/2'>{iconIndicator}</div>}

            {
                !isEditableV2 && (
                    <div onClick={handlerChangeEditable} className='group absolute transform top-1/2 -translate-y-1/2 right-0 cursor-pointer h-full w-16 pl-3 flex'>
                        <IconEdit className="w-4 fill-gray-400 group-hover:fill-primary1-500" />
                    </div>
                )
            }

            {
                type === 'password' && (
                    <div onClick={() => setIsShowPassword(!isShowPassword)} className='group absolute top-1.5 right-0 cursor-pointer p-2'>
                        {isShowPassword
                            ? <IconEyeShow className={'text-primary1-500 w-5'} />
                            : <IconEyeHide className={'group-hover:text-primary1-500 text-gray-400 w-5'} />}
                    </div>
                )
            }

            {error && !showNoError && error.message && (
                <div className="text-semantic-error_base text-sm mt-2">
                    {error.message}
                </div>
            )}

            {isApply && <div onClick={handlerApply} className='group absolute transform top-1/2 -translate-y-1/2 right-3 cursor-pointer flex' data-automation={dataAutomationForApply}>
                <div className={`bg-primary1-500 rounded-md py-[3px] px-[15px] text-basic-white font-semibold ${!inputValue ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} >
                    Apply
                </div>
            </div>}

            {watch && watch(id) && isClearable && <div onClick={handlerClearInput} className='group absolute transform top-1/2 -translate-y-1/2 right-0 cursor-pointer h-full w-12 pl-3 flex'>
                <IconClose className="w-6 stroke-gray-400 group-hover:stroke-primary1-500" />
            </div>}

            {isShowCopyIcon && onCopy && <div onClick={onCopy} className='group absolute transform top-1/2 -translate-y-1/2 -right-1 cursor-pointer h-full w-12 pl-3 flex'>
                <IconCopyThin size={22} className="fill-gray-700 group-hover:fill-primary1-500" />
            </div>}
        </div>
    )
});


Input.defaultProps = {
    isRequiredField: false,
    isEditable: true,
    disabled: false,
    placeholderColor: 'light',
}
Input.propTypes = {
    mandatory: PropTypes.bool, 
    minimumLength: PropTypes.number, 
    maximumLength: PropTypes.number, 
    validationHandler: PropTypes.object, 
    handleKeyPress: PropTypes.func,
    type: PropTypes.string.isRequired,
    register: PropTypes.func,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    isRequiredField: PropTypes.bool,
    isEditable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(['simple', undefined]),
    placeholderColor: PropTypes.oneOf(['dark', 'light', 'extraLight']),
};

export default ValidateUserInput(Input);